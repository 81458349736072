<template>
    <notary-layout
        page-name="Notary Login"
        :loading="isLoading"
        :loading-title="loadingTitle"
        :error="isError"
        :error-text="errorText"
        :is-logged-in="false"
        hide-nav
    >
        <div
            class="button-wrapper-wrapper"
            v-show="!isLoading"
        >
            <div class="button-wrapper">
                <p class="fw-light h4">
                    Sign in to Aven Admin
                </p>
                <div class="d-grid">
                    <button
                        class="btn btn-primary mt-3"
                        @click="oauthLogin"
                    >
                        Sign In With Google
                    </button>
                </div>
            </div>
        </div>
    </notary-layout>
</template>

<script>
    import NotaryLayout from '@/layouts/remoteNotarization/Notary'
    import { logger } from '@/utils/logger'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { registerNotary } from '@/services/remoteNotarizationApi'
    import { Flows, getNextRoute } from '@/flow/flowController'

    export default {
        components: {
            'notary-layout': NotaryLayout,
        },
        data: function () {
            return {
                isLoading: true,
                loadingTitle: 'Loading...',
                errorText: '',
                authCode: this.$route.query?.code,
            }
        },
        created: function () {
            appSessionStorage.setItem(localStorageKey.startPagePath, window.location.pathname)
            appSessionStorage.setItem(localStorageKey.currentFlow, Flows.notaryAdmin)
        },
        computed: {
            isError: function () {
                return !!this.errorText
            },
        },
        mounted: async function () {
            if (this.authCode) {
                logger.log('Found auth code, attempting to sign in')
                await this.setCredsAndLogIn()
            } else {
                logger.log('No auth code found, waiting for user to sign in')
                this.isLoading = false
            }
        },
        methods: {
            oauthLogin: function () {
                // ex: https://notary-login.notary.test.cluster.aven.com/notary/login
                const hostnameParts = window.location.hostname.split('.')

                let branch
                let mode
                // We're dealing with an old URL format
                // old hostname: test.notary.aven.com
                // [ 'test', 'notary', 'aven', 'com' ]
                // [ 'notary', 'aven', 'com' ]
                if (hostnameParts[2] === 'aven' || window.location.hostname === 'notary.aven.com') {
                    mode = 'old'
                    if (window.location.hostname === 'notary.aven.com') {
                        branch = 'prod'
                    } else {
                        branch = hostnameParts[0]
                    }
                }
                // new hostname: notary-login.notary.test.cluster.aven.com
                // [ 'notary-login', 'notary', 'test', 'cluster', 'aven', 'com' ]
                else {
                    branch = hostnameParts[0] // the unique branch name (notary-login)
                    // hostnameParts[1] is the project (notary) which is not useful to us here
                    mode = hostnameParts[2] // the cluster mode (test / staging / prod)
                }

                let url
                if (branch === 'localhost') {
                    url = `https://${process.env.VUE_APP_OAUTH_ENDPOINT}/localhost`
                } else {
                    console.log('Using new cluster schema to generate link')
                    url = `https://${process.env.VUE_APP_OAUTH_ENDPOINT}/${mode}/${branch}`
                }

                logger.log(`Redirecting to fully computed oauth URL: ${url}`)
                return (window.location = url) // No-op, but nothing should be done after this line
            },
            setCredsAndLogIn: async function () {
                logger.log(`Attempting to set creds and log in with ${this.authCode}`)

                let notaryResponse
                try {
                    notaryResponse = await registerNotary(this.authCode)
                    appSessionStorage.setItem(localStorageKey.notaryAccessJWT, notaryResponse.data.payload.jwt.accessJWT)
                    appSessionStorage.setItem(localStorageKey.notaryId, notaryResponse.data.payload.notaryId.toString())
                    appSessionStorage.setItem(localStorageKey.notaryName, notaryResponse.data.payload.notaryName)

                    // Clear the code from the URL before doing anywhere else
                    // This is important so people who 'backspace' won't have old codes still
                    // This also does not reload the page (like setting window.location.search = '' directly would)
                    window.history.replaceState(null, null, window.location.pathname)

                    return await this.$router.push(getNextRoute(this.$router))
                } catch (e) {
                    // Clear the code from the URL before doing anywhere else
                    // This is important so people who 'backspace' won't have old codes still
                    // This also does not reload the page (like setting window.location.search = '' directly would)
                    window.history.replaceState(null, null, window.location.pathname)

                    if (notaryResponse?.data?.error === 'notary_does_not_exist_error') {
                        this.errorText = 'Error: email not associated with a known notary'
                    } else {
                        this.errorText = "Try logging in again, seems like something didn't work quite right!"
                    }
                } finally {
                    this.isLoading = false
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/pages/notary/login';
</style>
